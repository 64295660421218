type I18n = any
type SaveType = "save" | "remove" | "update"

export async function useExplicitSave(createSnackbar: (snackbar: Snackbar) => Snackbar, $i18n: I18n, f: () => Promise<void | string>, type: SaveType = "save") {
  const savingMessage = type === "save" ? "saving" : type === "remove" ? "removing" : "updating"
  const savedMessage = type === "save" ? "saved" : type === "remove" ? "removed" : "updated"
  const saveFailedMessage = type === "save" ? "saveFailed" : type === "remove" ? "removeFailed" : "updateFailed"

  const snackbar = createSnackbar({
    title: $i18n.t(savingMessage),
    type: "info",
    timeout: -1,
    closable: false,
    pending: true,
  })

  try {
    const caption = await f()

    snackbar.title = $i18n.t(savedMessage)
    snackbar.type = type === "remove" ? "info" : "success"
    snackbar.timeout = 3000
    snackbar.text = caption ? caption : ""
    snackbar.closable = true
    snackbar.pending = false

    return true
  } catch (e: any) {
    console.error(e)

    snackbar.title = $i18n.t(saveFailedMessage)
    snackbar.type = "error"
    snackbar.timeout = 3000
    snackbar.text = `${e?.scopes ?? ''} ${String(e?.message ?? e ?? "Unknown error")}`.trim()
    snackbar.closable = true
    snackbar.pending = false

    return false
  }
}
